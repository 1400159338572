
if (!window._genesys.widgets.extensions) {
  window._genesys.widgets.extensions = {};
}

window._genesys.widgets.extensions.OlsExtension = function($, CXBus, Common) {
  'use strict';

  function OlsExtension() {
    this.plugin = CXBus.registerPlugin('OlsExtension');
    this.registerAndSubscribe();
  }

  OlsExtension.prototype.registerAndSubscribe = function() {
    var self;

    self = this;
    this.plugin.registerCommand('olsStart',
      function(e) {
        if (!window._genesys.widgets.webchat.userData || !e.data.userData || !window._genesys.widgets.webchat.chatButton.template) {
          e.deferred.reject();
        }
        $.extend(window._genesys.widgets.webchat.userData, e.data.userData);
        self.plugin.publish('olsStarted');
        e.deferred.resolve();
      }
    );
    this.plugin.registerCommand('olsEnd',
      function(e) {
        self.plugin.publish('olsEnded');
        e.deferred.resolve();
      }
    );
    this.plugin.registerCommand('olsStartChat',
      function(e) {
        if (!window._genesys.widgets.webchat.userData || !window._genesys.widgets.webchat.form) {
          e.deferred.reject();
        }
        $.extend(window._genesys.widgets.webchat.userData, e.data.userData);
        self.plugin.publish('olsStarted');
        self.plugin.publish('olsChatStarted');
        e.deferred.resolve();
      }
    );
    this.plugin.registerCommand('olsCheckStatus',
      function(e) {
        if (e.data.LiveAgentRtsSkill) {
          self.plugin.subscribe('StatusExtension.ready',
            function() {
              self.plugin.command('StatusExtension.ophalenRtsOnce',
              {
                liveAgentRtsSkill: e.data.LiveAgentRtsSkill
              })
              .done(
                function(data) {
                  if (data) {
                    e.deferred.resolve(data);
                    self.plugin.publish('statusOnce', {statusOnce: data});
                  }
                }
              );
            }
          );
        }
      }
    );
    this.plugin.subscribe('OlsExtension.ready',
      function() {
        self.plugin.command('OlsExtension.olsCheckStatus');
      }
    );

    this.plugin.republish('ready');
    this.plugin.ready();
  };
  return new OlsExtension();
};
