if (!window._genesys.widgets.extensions) {
  window._genesys.widgets.extensions = {};
}

/* eslint-disable max-statements */
window._genesys.widgets.extensions.StatusExtension = function($, CXBus, Common) {
  'use strict';

  function StatusExtension() {
    this.chatBotTwentyFourSeven = false;
    this.inChatStatusPublishing = false;
    this.ophalenRtsJqxhr = null;
    this.plugin = CXBus.registerPlugin('StatusExtension');
    this.pollOphalenRts = null;
    this.rtsStatus = null;
    this.timeOut = 10000;
    this.registerAndSubscribe();
  }

  StatusExtension.prototype.rtsStatusEnum = {
    Online: 'Online',
    Busy: 'Busy',
    Offline: 'Offline'
  };

  StatusExtension.prototype.startPolling = function(activity, chatBotTwentyFourSeven) {
    this.rtsStatus = null;
    if (activity && (!chatBotTwentyFourSeven || this.inChatStatusPublishing)) {
      this.ophalenRts(activity);
    }
    if (chatBotTwentyFourSeven) {
      this.publishStatus(this.rtsStatusEnum.Online);
    }
  };

  StatusExtension.prototype.stopPolling = function() {
    this.stopPollingOphalenRts();
    this.inChatStatusPublishing = false;
    this.plugin.publish('statusPollingStopped');
  };

  StatusExtension.prototype.stopPollingOphalenRts = function() {
    var self = this;

    if (this.ophalenRtsJqxhr) {
      this.ophalenRtsJqxhr.abort();
      this.ophalenRtsJqxhr = null;
    }
    if (this.pollOphalenRts) {
      clearTimeout(self.pollOphalenRts);
      this.pollOphalenRts = null;
    }
    this.plugin.publish('pollingOphalenRtsStopped');
  };

  StatusExtension.prototype.setPollOphalenRts = function(activity) {
    var self = this;

    this.pollOphalenRts = setTimeout(
      function() {
        self.ophalenRts(activity);
      },
      self.timeOut
    );
    this.plugin.publish('pollOphalenRts');
  };

  StatusExtension.prototype.ophalenRts = function(activity) {
    var self = this;

    if (typeof activity === 'undefined' || activity === null) {
      this.publishStatus(self.rtsStatusEnum.Offline);
    } else {
      this.ophalenRtsJqxhr = $.ajax({
        type: 'POST',
        url: '/ApiProxy/GenesysRTS/OphalenRTS',
        data: JSON.stringify({activity: activity}),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        dataType: 'json'
      });
      this.ophalenRtsJqxhr.done(
        function(data) {
          self.publishStatus(self.getRtsStatus(data));
          self.setPollOphalenRts(activity);
        }
      );
      this.ophalenRtsJqxhr.fail(
        function() {
          self.publishStatus(self.rtsStatusEnum.Offline);
          self.setPollOphalenRts(activity);
        }
      );
      this.plugin.publish('statusPollingStarted');
    }
  };

  StatusExtension.prototype.ophalenRtsOnce = function(activity) {
    var self = this;

    if (typeof activity === 'undefined' || activity === null) {
      this.publishStatus(self.rtsStatusEnum.Offline);
    } else {
      $.ajax({
        type: 'POST',
        url: '/ApiProxy/GenesysRTS/OphalenRTS',
        data: JSON.stringify({activity: activity}),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        dataType: 'json'
      })
      .done(
        function(data) {
          self.rtsStatusOnce = self.getRtsStatus(data);
          self.rtsStatus = self.rtsStatusOnce;
        }
      )
      .fail(
        function() {
          self.rtsStatusOnce = self.rtsStatusEnum.Offline;
        }
      );
    }
  };

  StatusExtension.prototype.publishStatus = function(rtsStatus) {
    var self = this;

    if (rtsStatus !== this.rtsStatus) {
      if (this.inChatStatusPublishing) {
        this.plugin.publish('inChatStatusUpdated',
          {
            rtsStatus: rtsStatus
          }
        );
      } else {
        this.rtsStatus = rtsStatus;
        this.plugin.publish('statusUpdated',
          {
            rtsStatus: self.rtsStatus
          }
        );
      }
    }
  };

  StatusExtension.prototype.getRtsStatus = function(data) {
    var groupStatus;

    groupStatus = '_AM_CTS_GroupStatus|CurrentMargin|chat';

    if (typeof data === 'undefined' || data === null) {
      return this.rtsStatusEnum.Offline;
    }

    if (this.getRtsStats('_AM_CN_LoggedInChat', data.stats) <= 0) {
      return this.rtsStatusEnum.Offline;
    } else if (this.getRtsStats(groupStatus, data.stats) <= 0) {
      return this.rtsStatusEnum.Busy;
    } else if (this.getRtsStats(groupStatus, data.stats) > 0) {
      return this.rtsStatusEnum.Online;
    }
    return this.rtsStatusEnum.Offline;
  };

  StatusExtension.prototype.getRtsStats = function(nameKey, obj) {
    var res;

    if (typeof obj === 'undefined' || obj === null) {
      return false;
    }

    if (obj.hasOwnProperty(nameKey)) {
      return obj[nameKey];
    }
    res = Object.keys(obj).filter(function(k) {
      return (k.toLowerCase().indexOf(nameKey.toLowerCase()) > -1) || (nameKey.toLowerCase().indexOf(k.toLowerCase()) > -1);
    });
    if (res) {
      return obj[res];
    }
    return false;
  };

  StatusExtension.prototype.registerAndSubscribe = function() {
    var self;

    self = this;
    this.plugin.registerCommand('startPolling',
      function(e) {
        self.inChatStatusPublishing = e.data.inChatStatusPublishing;
        self.startPolling(e.data.activity, e.data.chatBotTwentyFourSeven);
        e.deferred.resolve();
      }
    );
    this.plugin.registerCommand('stopPolling',
      function(e) {
        self.stopPolling();
        e.deferred.resolve();
      }
    );
    this.plugin.registerCommand('ophalenRtsOnce',
      function(e) {
        self.ophalenRtsOnce(e.data.liveAgentRtsSkill);
        self.stopPolling();
        e.deferred.resolve(self.rtsStatusOnce);
      }
    );
    this.plugin.registerCommand('getStatus',
      function(e) {
        e.deferred.resolve(self.rtsStatus);
      }
    );
    this.plugin.registerCommand('getStatusOnce',
      function(e) {
        e.deferred.resolve(self.rtsStatusOnce);
      }
    );
    this.plugin.registerCommand('updateStatus',
      function(e) {
        self.publishStatus(e.data.rtsStatus);
        e.deferred.resolve();
      }
    );
    this.plugin.republish('ready');
    this.plugin.ready();
  };

  return new StatusExtension();
};
/* eslint-enable max-statements */
