/* eslint-disable max-statements */
if (!window._genesys.widgets.extensions) {
  window._genesys.widgets.extensions = {};
}

/* eslint-disable max-statements */
window._genesys.widgets.extensions.DomExtension = function($, CXBus, Common) {
  'use strict';

  function DomExtension() {
    this.chatType = null;
    this.connectedAgentNames = [];
    this.minimized = false;
    this.mopinionToggler = null;
    this.plugin = CXBus.registerPlugin('DomExtension');
    this.init();
    this.registerAndSubscribe();
  }

  DomExtension.prototype.cssStatusEnum = {
    Online: 'is-available',
    Offline: 'is-offline'
  };

  DomExtension.prototype.cssChatTypeEnum = {
    LiveChat: 'is-livechat',
    ChatBot: 'is-chatbot'
  };

  DomExtension.prototype.meetIdStatusEnum = {
    Offline: '_offline',
    Online: '_online'
  };

  DomExtension.prototype.chatTypeEnum = {
    LiveChat: 'livechat',
    Chatbot: 'chatbot'
  };

  DomExtension.prototype.setMeetId = function(query, text, addText) {
    var $query;

    $query = $(query);
    if ($query.length > 0) {
      this.setDataMeetId($query, text, addText);
    }
  };

  DomExtension.prototype.setDataMeetId = function($query, dataMeetId, addText) {
    var text;

    if (dataMeetId) {
      if ($query.length > 0) {
        $query.removeAttr('data-meetid');
        $query.removeAttr('id');
        if (addText) {
          text = dataMeetId + $query.text().replace(/[^\w\s]/g, '').replace(/ /g, '_').toLowerCase();
          $query.attr('data-meetid', text);
          $query.attr('id', text);
        } else {
          $query.attr('data-meetid', dataMeetId);
          $query.attr('id', dataMeetId);
        }
      }
    }
  };

  DomExtension.prototype.initCallToAction = function() {
    var self;

    self = this;
    this.$callToAction = $('.js-genesys').on('click',
      function(e) {
        e.preventDefault();
        self.plugin.command('WebChat.open');
      }
    );
  };

  DomExtension.prototype.initWidget = function() {
    this.$widget = $('.cx-widget').first();
    if (this.$widget.length > 0) {
      switch (this.chatType) {
        case this.chatTypeEnum.Chatbot:
          this.switchChatType(this.cssChatTypeEnum.ChatBot, this.cssChatTypeEnum.LiveChat);
          break;
        default:
          this.switchChatType(this.cssChatTypeEnum.LiveChat, this.cssChatTypeEnum.ChatBot);
          break;
      }
    }
  };

  DomExtension.prototype.switchChatType = function(chatTypeToAdd, chatTypeToRemove) {
    this.$widget.removeClass(chatTypeToRemove);
    this.$widget.removeClass(chatTypeToAdd);
    this.$widget.addClass(chatTypeToAdd);
  };

  DomExtension.prototype.initChatButton = function() {
    this.$chatButton = $('.cx-webchat-chat-button');
    this.setMeetId(this.$chatButton, 'ipoclick-lnk-' + this.chatType + '-start_chat' + this.meetIdStatusEnum.Offline, false);
  };

  DomExtension.prototype.initConfirmFormCloseButton = function() {
    this.setMeetId('.cx-close-confirm.cx-btn.cx-btn-primary.i18n', 'ipoclick-btn-' + this.chatType + '-close_' + this.chatType + '_', true);
  };

  DomExtension.prototype.initConfirmFormCancelButton = function() {
    this.setMeetId('.cx-close-cancel.cx-btn.cx-btn-default.i18n', 'ipoclick-btn-' + this.chatType + '-close_' + this.chatType + '_', true);
  };

  DomExtension.prototype.initChatFormSubmitButton = function() {
    this.setMeetId('.cx-submit.cx-btn.cx-btn-primary.i18n', 'ipoclick-btn-' + this.chatType + '-', true);
  };

  DomExtension.prototype.initChatFormCancelButton = function() {
    this.setMeetId('.cx-btn.cx-btn-default.i18n', 'ipoclick-btn-' + this.chatType + '-', true);
  };

  DomExtension.prototype.initChatWindow = function() {
    var rouActiviteit;

    if (typeof window._genesys.widgets.webchat.userData !== 'undefined') {
      if (window._genesys.widgets.webchat.userData.rouActiviteit) {
        rouActiviteit = '-' + window._genesys.widgets.webchat.userData.rouActiviteit.toLowerCase();
      }
      this.setMeetId('.cx-webchat', 'ipoview-window-' + this.chatType + rouActiviteit, false);
    }
  };

  DomExtension.prototype.initChatWindowTitle = function() {
    var self;

    self = this;
    this.$title = $('.cx-titlebar .cx-title');
    if (this.$title.length > 0) {
      this.$title.text(self.connectedAgentNames.join(', ') || self.$title.text());
    }
  };

  DomExtension.prototype.initChatWindowSubTitle = function() {
    var $subtitle;

    $subtitle = $('.cx-titlebar .cx-subtitle');
    if ($subtitle.length > 0) {
      $subtitle.html(window._genesys.widgets.custom.subtitleUrlString);
    }
  };

  DomExtension.prototype.initSendMessageButton = function() {
    var $sendMessageButton;

    $sendMessageButton = $('.cx-send.cx-icon.i18n');
    if ($sendMessageButton.length > 0) {
      $sendMessageButton.text($sendMessageButton.prop('title'));
      this.setDataMeetId($sendMessageButton, 'ipoclick-btn-' + this.chatType + '-', true);
    }
  };

  DomExtension.prototype.initChatFormMinimizeIcon = function() {
    var chatFormMinimizeMeetText;

    chatFormMinimizeMeetText = 'ipoclick-btn-' + this.chatType + '-minimize_chat';
    if (this.minimized) {
      chatFormMinimizeMeetText = 'ipoclick-btn-' + this.chatType + '-maximize_chat';
    }
    this.setMeetId('.cx-icon.cx-button-minimize', chatFormMinimizeMeetText, false);
  };

  DomExtension.prototype.initChatFormCloseIcon = function() {
    var $chatFormCloseIcon, self;

    self = this;
    $chatFormCloseIcon = $('.cx-icon.cx-button-close');
    function chatFormCloseIconFunc() {
      self.initChatEndCancelButton();
      self.initChatEndConfirmButton();
    }
    if ($chatFormCloseIcon.length > 0) {
      this.setDataMeetId($chatFormCloseIcon, 'ipoclick-btn-' + this.chatType + '-close_chat', false);
      $chatFormCloseIcon.off('click.domextension');
      $chatFormCloseIcon.on('click.domextension', chatFormCloseIconFunc);
    }
  };

  DomExtension.prototype.initChatEndCancelButton = function() {
    this.setMeetId('.cx-end-cancel.cx-btn.cx-btn-default.i18n', 'ipoclick-btn-' + this.chatType + '-close_' + this.chatType + '_', true);
  };

  DomExtension.prototype.initChatEndConfirmButton = function() {
    var $chatEndConfirmButton, self;

    self = this;
    $chatEndConfirmButton = $('.cx-end-confirm.cx-btn.cx-btn-primary.i18n');
    function chatEndConfirmButtonFunc() {
      self.plugin.command('WebChat.close');
    }
    if ($chatEndConfirmButton.length > 0) {
      this.setDataMeetId($chatEndConfirmButton, 'ipoclick-btn-' + this.chatType + '-close_' + this.chatType + '_', true);
      $chatEndConfirmButton.off('click.domextension');
      $chatEndConfirmButton.on('click.domextension', chatEndConfirmButtonFunc);
    }
  };

  DomExtension.prototype.initChatFormErrorButton = function(reconnected) {
    var $chatFormErrorButton, self;

    self = this;
    setTimeout(function() {
      $chatFormErrorButton = $('.cx-error-button.cx-btn.cx-btn-primary.i18n');
      function chatFormErrorButtonFunc() {
        self.plugin.command('WebChatService.endChat');
        self.plugin.command('WebChat.close');
      }
      if ($chatFormErrorButton.length > 0) {
        self.setMeetId('.cx-error-button.cx-btn.cx-btn-primary.i18n', 'ipoclick-btn-' + self.chatType + '-', true);
        $chatFormErrorButton.off('click.domextension');
        if (!reconnected) {
          $chatFormErrorButton.on('click.domextension', chatFormErrorButtonFunc);
        }
      }
    }, 250);
  };

  DomExtension.prototype.initToasterFormPrimaryButton = function() {
    this.setMeetId('.cx-btn.cx-btn-primary', 'ipoclick-btn-' + this.chatType + '-', true);
  };

  DomExtension.prototype.initToasterFormDefaultButton = function() {
    this.setMeetId('.cx-btn.cx-btn-default', 'ipoclick-btn-' + this.chatType + '-', true);
  };

  DomExtension.prototype.initSubTitleLink = function() {
    this.setMeetId('.cx-subtitle-link', 'ipoclick-lnk-' + this.chatType + '-', true);
  };

  DomExtension.prototype.initUrlsInMessages = function() {
    var $urlsInMessages, $urlInMessage, $urlInMessageDataMeetId, self;

    self = this;
    $urlsInMessages = $('.cx-message-text').find('a');
    if ($urlsInMessages.length > 0) {
      $.each($urlsInMessages, function(index) {
        $urlInMessage = $($urlsInMessages[index]);
        $urlInMessageDataMeetId = $urlInMessage.attr('datameetid');
        if ($urlInMessage.length > 0 && $urlInMessageDataMeetId) {
          self.setDataMeetId($urlInMessage, 'ipoclick-lnk-' + self.chatType + '-', true);
        }
      });
    }
  };

  DomExtension.prototype.initOlsChatBody = function() {
    var self;

    self = this;
    this.$olsChatBody = $('.ols-contact-chat-body');
    if (window._genesys.widgets.webchat.chatButton) {
      this.$olsChatBody.html(window._genesys.widgets.webchat.chatButton.template);
    }
    this.olsChatBodyFunc = function() {
      self.plugin.command('OlsExtension.olsStartChat');
    };
    this.$olsChatBody.off('click.domextension');
    this.$olsChatBody.on('click.domextension', self.olsChatBodyFunc);
  };

  DomExtension.prototype.initOlsChatButton = function() {
    this.$olsChatButton = $('.cx-ols-livechat-button');
    if (this.$olsChatButton.length > 0) {
      this.setDataMeetId(this.$olsChatButton, 'ipoclick-lnk-' + this.chatType + '-start_chat' + this.meetIdStatusEnum.Offline, false);
    }
  };

  DomExtension.prototype.initOlsLabelTitle = function() {
    var $olsLabelTitle;

    $olsLabelTitle = $('.cx-ols-label-title');
    if ($olsLabelTitle.length > 0) {
      $olsLabelTitle.text(window._genesys.widgets.custom.customPlaceholders.olsLabelTitle);
    }
  };

  DomExtension.prototype.initOlsLabelSubTitle = function() {
    this.$olsLabelSubtitle = $('.cx-ols-label-subtitle');
  };

  DomExtension.prototype.initTranscript = function() {
    this.$transcript = $('.cx-transcript');
  };

  DomExtension.prototype.initMopinion = function() {
    this.$mopinion = $('.mopinion-survey-content .btn-open-survey.tab');
  };

  DomExtension.prototype.init = function(reconnected) {
    this.initCallToAction();
    this.initWidget();
    this.initChatButton();
    this.initConfirmFormCloseButton();
    this.initConfirmFormCancelButton();
    this.initChatFormSubmitButton();
    this.initChatFormCancelButton();
    this.initChatWindow();
    this.initChatWindowTitle();
    this.initChatWindowSubTitle();
    this.initSendMessageButton();
    this.initChatFormMinimizeIcon();
    this.initChatFormCloseIcon();
    this.initChatEndCancelButton();
    this.initChatEndConfirmButton();
    this.initChatFormErrorButton(reconnected);
    this.initToasterFormPrimaryButton();
    this.initToasterFormDefaultButton();
    this.initSubTitleLink();
    this.initUrlsInMessages();
    this.initOlsChatBody();
    this.initOlsChatButton();
    this.initOlsLabelTitle();
    this.initOlsLabelSubTitle();
    this.initTranscript();
    this.initMopinion();
  };

  DomExtension.prototype.removeClasses = function($element) {
    var self;

    self = this;
    $.each(self.cssStatusEnum, function(index, value) {
      $element.removeClass(value);
    });
  };

  DomExtension.prototype.toggleMopinion = function(toggle) {
    var self;

    self = this;
    this.mopinionToggler = setTimeout(
      function() {
        if (toggle) {
          self.$mopinion.attr('style', 'z-index: 0!important;');
        } else {
          self.$mopinion.removeAttr('style');
        }
      },
      100
    );
  };

  DomExtension.prototype.initStatus = function(cssStatusEnumStatus, meetIdStatusEnumStatus, olsLabelSubtitle, reconnected) {
    this.init(reconnected);
    this.removeClasses(this.$widget);
    this.$widget.addClass(cssStatusEnumStatus);
    this.setDataMeetId(this.$chatButton, 'ipoclick-lnk-' + this.chatType + '-start_chat' + meetIdStatusEnumStatus, false);
    this.removeClasses(this.$olsChatButton);
    this.$olsChatButton.addClass(cssStatusEnumStatus);
    this.setDataMeetId(this.$olsChatButton, 'ipoclick-lnk-' + this.chatType + '-start_chat' + meetIdStatusEnumStatus, false);
    this.$olsLabelSubtitle.text(olsLabelSubtitle);
  };

  DomExtension.prototype.registerAndSubscribe = function() {
    var self = this;

    this.plugin.registerCommand('updateHtml',
      function(e) {
        switch (e.data.rtsStatus) {
          case 'Online':
            self.initStatus(self.cssStatusEnum.Online, self.meetIdStatusEnum.Online, window._genesys.widgets.custom.customPlaceholders.olsLabelSubtitleOnline, true);
            break;
          case 'Busy':
              self.initStatus(self.cssStatusEnum.Online, self.meetIdStatusEnum.Online, window._genesys.widgets.custom.customPlaceholders.olsLabelSubtitleOnline);
              break;
          default:
            self.$title.text(window._genesys.widgets.custom.customPlaceholders.chatTitle);
            self.initStatus(self.cssStatusEnum.Offline, self.meetIdStatusEnum.Offline, window._genesys.widgets.custom.customPlaceholders.olsLabelSubtitleOffline);
            break;
        }
        e.deferred.resolve();
        self.plugin.publish('htmlUpdated');
      }
    );
    this.plugin.registerCommand('updateConnectedAgentNames',
      function(e) {
        self.connectedAgentNames = e.data.connectedAgentNames;
        e.deferred.resolve();
      }
    );
    this.plugin.registerCommand('toggleMopinion',
      function(e) {
        self.toggleMopinion(e.data.toggle);
        e.deferred.resolve();
      }
    );
    this.plugin.registerCommand('scrollTranscript',
      function(e) {
        setTimeout(
          function() {
            self.$transcript.animate(
              {scrollTop: self.$transcript.prop('scrollHeight')}
            );
          },
          1500
        );
        e.deferred.resolve();
      }
    );
    this.plugin.registerCommand('removeOlsChatBody',
      function(e) {
        self.$olsChatBody.empty();
        self.$olsChatBody.off('click.domextension');
        e.deferred.resolve();
      }
    );
    this.plugin.registerCommand('setChatType',
      function(e) {
        self.chatType = e.data.chatType;
        self.plugin.publish('chatTypeSet',
        {
          chatType: self.chatType
        });
        e.deferred.resolve();
      }
    );
    this.plugin.registerCommand('minimize',
      function(e) {
        self.minimized = e.data.minimized;
        e.deferred.resolve();
      }
    );
    this.plugin.registerCommand('disableEnableInput',
      function(e) {
        $('.cx-input').attr('disabled', e.data.disabled);
      }
    );
    this.plugin.republish('ready');
    this.plugin.ready();
  };
  return new DomExtension();
};
/* eslint-enable max-statements */
