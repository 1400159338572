if (!window._genesys.widgets.extensions) {
  window._genesys.widgets.extensions = {};
}

window._genesys.widgets.extensions.CookieExtension = function($, CXBus, Common) {
  'use strict';

  function CookieExtension() {
    this.plugin = CXBus.registerPlugin('CookieExtension');
    this.registerAndSubscribe();
  }

  CookieExtension.prototype.setCookie = function(name, value, days) {
    var expires, date, domain;

    expires = '';
    if (days) {
      date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/;domain=' + document.domain.match(/[.][^.]*.[^.]*$/)[0] + ';';
  };

  CookieExtension.prototype.getCookie = function(name) {
    var cname, decodedCookie, ca, i, c;

    cname = name + '=';
    decodedCookie = decodeURIComponent(document.cookie);
    ca = decodedCookie.split(';');
    for (i = 0; i < ca.length; i++) {
      c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(cname) === 0) {
        return c.substring(cname.length, c.length);
      }
    }
    return '';
  };

  CookieExtension.prototype.registerAndSubscribe = function() {
    var self;

    self = this;
    this.plugin.registerCommand('setCookie',
      function(e) {
        self.setCookie(e.data.name, e.data.value, e.data.days);
        e.deferred.resolve(e.data.value);
        self.plugin.publish('CookieSet');
      }
    );
    this.plugin.registerCommand('getCookie',
      function(e) {
        e.deferred.resolve(self.getCookie(e.data.name));
        self.plugin.publish('gotCookie');
      }
    );
    this.plugin.republish('ready');
    this.plugin.ready();
  };

  return new CookieExtension();
};
