/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable id-length */
/* eslint-disable no-magic-numbers */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable max-statements */
if (!window._genesys.widgets.extensions) {
  window._genesys.widgets.extensions = {};
}

window._genesys.widgets.extensions.FunctionalExtension = function($, CXBus, Common) {
  'use strict';

  function FunctionalExtension() {
    self.agentNames = [];
    this.busyInjectMessageCommandData = false;
    this.chatBotTwentyFourSeven = false;
    this.chatBotNickname = null;
    this.chatBotNicknameCookieName = '_genesys.widgets.custom.chatBotNickname';
    this.chatTypeCookieName = '_genesys.widgets.custom.chatTypeCookieName';
    this.chatSession = '_genesys.widgets.webchat.state.session';
    this.genesysId = 'GenesysID';
    this.chatType = 'ChatType';
    this.chatSkill = 'ChatSkill';
    this.bekendeGebruiker = 'BekendeGebruiker';
    this.activity = null;
    this.plugin = CXBus.registerPlugin('FunctionalExtension');
    this.rtsStatus = null;
    this.webchatClosed = false;
    this.webChatServiceRestored = false;
    this.registerAndSubscribe();
    this.setUserData();
    this.startPolling(false);
  }

  FunctionalExtension.prototype.rtsStatusEnum = {
    Online: 'Online',
    Busy: 'Busy',
    Offline: 'Offline'
  };

  FunctionalExtension.prototype.chatTypeEnum = {
    LiveChat: 'livechat',
    ChatBot: 'chatbot'
  };

  FunctionalExtension.prototype.setMopinionData = function(key, value) {
    if (key && value) {
      if (window) {
        window[key] = value;
      }
    }
  };

  FunctionalExtension.prototype.deleteMopinionData = function(key) {
    if (key) {
      if (window) {
        delete window[key];
      }
    }
  };

  FunctionalExtension.prototype.setGenesysId = function() {
    var self = this;

    this.plugin.command('CookieExtension.getCookie',
    {
      name: self.chatSession
    })
    .done(
      function(data) {
        if (data) {
          self.setMopinionData(self.genesysId, data);
        }
      }
    );
  };

  FunctionalExtension.prototype.resetUserData = function() {
    if (!window._genesys.widgets.webchat.userData) {
      return;
    }
    if (typeof window._genesys.widgets.webchat.userData.rouActiviteit !== 'undefined' && window._genesys.widgets.webchat.userData.rouActiviteit !== null) {
      window._genesys.widgets.webchat.userData.rouActiviteit = null;
    }
    if (typeof window._genesys.widgets.webchat.userData.rouLOB !== 'undefined' && window._genesys.widgets.webchat.userData.rouLOB !== null) {
      window._genesys.widgets.webchat.userData.rouLOB = null;
    }
    if (typeof window._genesys.widgets.webchat.userData.rouLabel !== 'undefined' && window._genesys.widgets.webchat.userData.rouLabel !== null) {
      window._genesys.widgets.webchat.userData.rouLabel = null;
    }
    if (typeof window._genesys.widgets.webchat.userData.LiveAgentRtsSkill !== 'undefined' && window._genesys.widgets.webchat.userData.LiveAgentRtsSkill !== null) {
      window._genesys.widgets.webchat.userData.LiveAgentRtsSkill = null;
    }
  };

  FunctionalExtension.prototype.setUserData = function() {
    var self = this;

    if (typeof window._genesys.widgets.webchat.userData !== 'undefined') {
      if (typeof window._genesys.widgets.webchat.userData.TwentyFourSeven === 'undefined') {
        this.chatBotTwentyFourSeven = false;
      } else {
        this.chatBotTwentyFourSeven = window._genesys.widgets.webchat.userData.TwentyFourSeven;
      }
      if (typeof window._genesys.widgets.webchat.userData.Nickname === 'undefined') {
        this.chatBotNickname = null;
      } else {
        this.chatBotNickname = window._genesys.widgets.webchat.userData.Nickname;
      }
      if (this.chatBotNickname) {
        this.plugin.command('CookieExtension.setCookie',
          {
            name: self.chatBotNicknameCookieName,
            value: self.chatBotNickname
          }
        );
      }
      if (typeof window._genesys.widgets.webchat.userData.rouActiviteit !== 'undefined' && window._genesys.widgets.webchat.userData.rouActiviteit !== null) {
        this.setMopinionData(this.chatSkill, window._genesys.widgets.webchat.userData.rouActiviteit.toLowerCase());
      }
      if (typeof window._genesys.widgets.webchat.userData.LiveAgentRtsSkill !== 'undefined' && window._genesys.widgets.webchat.userData.LiveAgentRtsSkill !== null) {
        this.activity = window._genesys.widgets.webchat.userData.LiveAgentRtsSkill;
      } else {
        this.activity = null;
      }
      if (typeof window._genesys.widgets.webchat.userData.SAP_BP_ID === 'undefined' || window._genesys.widgets.webchat.userData.SAP_BP_ID === null) {
        this.setMopinionData(this.bekendeGebruiker, 'nee');
      } else {
        this.setMopinionData(this.bekendeGebruiker, 'ja');
      }
    }
  };

  FunctionalExtension.prototype.setTypeOfChat = function() {
    var self = this;

    if (this.chatBotNickname) {
      this.plugin.command('DomExtension.setChatType', {chatType: self.chatTypeEnum.ChatBot});
    } else {
      this.plugin.command('DomExtension.setChatType', {chatType: self.chatTypeEnum.LiveChat});
    }
  };

  FunctionalExtension.prototype.setChatTypeCookie = function() {
    var chatTypeCookieValue = '',
        self = this;

    if (window._genesys.widgets.webchat.dataURL) {
      if (window._genesys.widgets.webchat.dataURL.indexOf('bot') > 0) {
        chatTypeCookieValue = 'bot';
      } else if (window._genesys.widgets.webchat.dataURL.indexOf('livechat') > 0) {
        chatTypeCookieValue = 'livechat';
      }
    }
    this.plugin.command('CookieExtension.setCookie',
    {
      name: self.chatTypeCookieName,
      value: chatTypeCookieValue
    });
    if (chatTypeCookieValue === 'livechat') {
      return true;
    }
  };

  FunctionalExtension.prototype.injectBusyMessageBasedOnCookie = function() {
    var self = this;

    this.plugin.command('CookieExtension.getCookie',
    {
      name: self.chatTypeCookieName
    })
    .done(
      function(data) {
        if (data === 'livechat') {
          self.injectBusyMessage();
        }
      }
    );
  };

  FunctionalExtension.prototype.injectBusyMessage = function() {
    var self = this;

    if ($.isEmptyObject(self.agentNames)) {
      self.startPolling(true);
      self.plugin.subscribe('StatusExtension.inChatStatusUpdated',
        function(e) {
          switch (e.data.rtsStatus) {
            case self.rtsStatusEnum.Busy:
              self.plugin.unsubscribe('StatusExtension.inChatStatusUpdated');
              self.plugin.command('StatusExtension.stopPolling');
              if (!self.busyMessageInjected) {
                self.injectMessages(window._genesys.widgets.custom.busyInjectMessageCommandData);
                self.busyMessageInjected = true;
              }
              break;
            case self.rtsStatusEnum.Online:
              self.plugin.unsubscribe('StatusExtension.inChatStatusUpdated');
              self.plugin.command('StatusExtension.stopPolling');
              break;
            default:
              break;
          }
        }
      );
    }
  };

  FunctionalExtension.prototype.startPolling = function(boolInChatStatusPublishing) {
    var self = this;

    this.plugin.command('StatusExtension.startPolling',
      {
        activity: self.activity,
        inChatStatusPublishing: boolInChatStatusPublishing,
        chatBotTwentyFourSeven: self.chatBotTwentyFourSeven
      });
  };

  FunctionalExtension.prototype.getConnectedAgentNames = function(agents) {
    var agentNames;

    if ($.isEmptyObject(agents)) {
      return [];
    }
    agentNames = [];
    $.each(agents, function(agentIndex, agentValue) {
      if (agents[agentIndex].connected) {
        if ($.inArray(agents[agentIndex].name, agentNames) === -1) {
          agentNames.push(agents[agentIndex].name);
        }
      }
    });
    return agentNames;
  };

  FunctionalExtension.prototype.setNickName = function() {
    if (window._genesys.widgets.webchat.form) {
      $.each(window._genesys.widgets.webchat.form.inputs, function(inputIndex, inputValue) {
        var array, str, cryptoObj, uint32Array, i;

        if (window._genesys.widgets.webchat.form.inputs[inputIndex].id === 'cx_webchat_form_nickname' && typeof window._genesys.widgets.webchat.form.inputs[inputIndex].value === 'undefined') {
          cryptoObj = window.crypto || window.msCrypto;
          /*  eslint-env es6  */
          uint32Array = new Uint32Array(8);
          array = cryptoObj.getRandomValues(uint32Array);
          str = '';
          for (i = 0; i < array.length; i++) {
            str = str + array[i].toString(16).slice(-4);
          }
          window._genesys.widgets.webchat.form.inputs[inputIndex].value = 'Bezoeker_' + str;
        }
      });
    }
  };

  FunctionalExtension.prototype.injectMessages = function(messagesToInject) {
    var self = this;

    if (messagesToInject) {
      messagesToInject.forEach(
        function(messageToInject) {
          self.plugin.command('WebChat.injectMessage', messageToInject);
        }
      );
    }
  };

  FunctionalExtension.prototype.updateHtml = function(boolInitLanguage) {
    var self = this;

    this.plugin.command('StatusExtension.getStatus')
    .done(
      function(data) {
        self.plugin.command('DomExtension.updateHtml', {rtsStatus: data})
        .done(
          function() {
            if (boolInitLanguage) {
              self.plugin.command('App.setLanguage', {lang: window._genesys.widgets.main.lang});
            }
          }
        );
      }
    );
  };

  FunctionalExtension.prototype.setChatTypeBasedOnChatBotNickname = function(agentNames) {
    var self = this;

    if (this.chatBotNickname) {
      this.setChatTypeBasedOnComparisonValue($.inArray(self.chatBotNickname, agentNames));
    } else {
      this.plugin.command('CookieExtension.getCookie',
      {
        name: self.chatBotNicknameCookieName
      })
      .done(
        function(data) {
          self.setChatTypeBasedOnComparisonValue($.inArray(data, agentNames));
        }
      );
    }
  };

  FunctionalExtension.prototype.setChatTypeBasedOnComparisonValue = function(comparisonIndexValue) {
    var self = this;

    if (comparisonIndexValue === -1) {
      this.plugin.command('DomExtension.setChatType', {chatType: self.chatTypeEnum.LiveChat});
    } else {
      this.plugin.command('DomExtension.setChatType', {chatType: self.chatTypeEnum.ChatBot});
    }
  };

  FunctionalExtension.prototype.registerAndSubscribe = function() {
    var self = this;

    this.plugin.before('WebChat.open',
      function(data) {
        self.webchatClosed = false;
        if (!data.restoring) {
          self.setNickName();
          data.userData = window._genesys.widgets.webchat.userData;
          data.form = {autoSubmit: true};
          data.formJSON = window._genesys.widgets.webchat.form;
          if (self.rtsStatus) {
            if (self.rtsStatus !== self.rtsStatusEnum.Offline) {
              return data;
            }
          }
          return false;
        }
        return data;
      }
    );
    this.plugin.subscribe('DomExtension.ready',
      function() {
        self.setTypeOfChat();
      }
    );
    this.plugin.subscribe('DomExtension.chatTypeSet',
      function(e) {
        self.setMopinionData(self.chatType, e.data.chatType);
        self.updateHtml(false);
      }
    );
    this.plugin.subscribe('StatusExtension.statusUpdated',
      function(e) {
        self.rtsStatus = e.data.rtsStatus;
        self.plugin.command('DomExtension.updateHtml', {rtsStatus: self.rtsStatus});
      }
    );
    this.plugin.subscribe('WebChat.chatButtonDisplayed',
      function() {
        self.updateHtml(false);
      }
    );
    this.plugin.subscribe('WebChat.opened',
      function() {
        self.plugin.command('StatusExtension.stopPolling');
        self.plugin.command('DomExtension.toggleMopinion', {toggle: true});
        if (self.webChatServiceRestored) {
          self.injectMessages(window._genesys.widgets.custom.restoredInjectMessageCommandData);
          self.injectBusyMessageBasedOnCookie();
          self.webChatServiceRestored = false;
        } else if (self.setChatTypeCookie()) {
          self.injectBusyMessage();
        }
        self.updateHtml(false);
      }
    );
    this.plugin.subscribe('WebChat.messageAdded',
      function() {
        self.updateHtml(false);
      }
    );
    this.plugin.subscribe('WebChatService.clientConnected',
      function() {
        self.plugin.command('DomExtension.disableEnableInput', {disabled: true});
        if (!self.webChatServiceRestored) {
          self.injectMessages(window._genesys.widgets.custom.welcomeInjectMessageCommandData);
        }
      }
    );
    this.plugin.subscribe('WebChatService.started',
      function() {
        self.setGenesysId();
        self.webChatServiceRestored = true;
      }
    );
    this.plugin.subscribe('WebChatService.restored',
      function() {
        self.setGenesysId();
        self.webChatServiceRestored = true;
      }
    );
    this.plugin.subscribe('WebChatService.restoreTimeout',
      function() {
        self.updateHtml(false);
      }
    );
    this.plugin.subscribe('WebChatService.agentConnected',
      function(e) {
        self.agentNames = self.getConnectedAgentNames(e.data.agents);
        self.plugin.command('DomExtension.updateConnectedAgentNames', {connectedAgentNames: self.agentNames});
        self.plugin.command('DomExtension.disableEnableInput', {disabled: false});
        self.setChatTypeBasedOnChatBotNickname(self.agentNames);
        self.plugin.command('StatusExtension.stopPolling');
        self.plugin.command('StatusExtension.updateStatus', {rtsStatus: self.rtsStatusEnum.Online});
      }
    );
    this.plugin.subscribe('WebChatService.agentDisconnected',
      function(e) {
        self.agentNames = self.getConnectedAgentNames(e.data.agents);
        self.plugin.command('DomExtension.updateConnectedAgentNames', {connectedAgentNames: self.agentNames});
        if ($.isEmptyObject(self.agentNames)) {
          self.plugin.command('StatusExtension.updateStatus', {rtsStatus: self.rtsStatusEnum.Offline});
          self.updateHtml(false);
        }
      }
    );
    this.plugin.subscribe('WebChat.minimized',
      function() {
        self.plugin.command('DomExtension.minimize', {minimized: true});
        self.updateHtml(false);
        self.plugin.command('DomExtension.toggleMopinion', {toggle: false});
      }
    );
    this.plugin.subscribe('WebChat.unminimized',
      function() {
        self.plugin.command('DomExtension.minimized', {minimized: false});
        self.updateHtml(false);
        self.plugin.command('DomExtension.toggleMopinion', {toggle: true});
      }
    );
    this.plugin.before('WebChat.close',
      function(data) {
        self.webchatClosed = true;
        return data;
      }
    );
    this.plugin.subscribe('WebChat.closed',
      function() {
        self.deleteMopinionData(self.genesysId);
        self.deleteMopinionData(self.chatType);
        self.deleteMopinionData(self.chatSkill);
        self.deleteMopinionData(self.bekendeGebruiker);
        self.setTypeOfChat();
        self.plugin.command('StatusExtension.updateStatus', {rtsStatus: self.rtsStatusEnum.Offline});
        self.updateHtml(false);
        self.plugin.command('DomExtension.toggleMopinion', {toggle: false});
        self.setUserData();
        self.startPolling(false);
      }
    );
    this.plugin.subscribe('WebChat.completed',
      function() {
        if (!self.webchatClosed) {
          self.injectMessages(window._genesys.widgets.custom.mopinionInjectMessageCommandData);
          if (Pastease !== 'undefined') {
            if (typeof Pastease.forceParse === 'function') {
              Pastease.forceParse();
            }
          }
        }
        self.plugin.command('DomExtension.scrollTranscript');
        // eslint-disable-next-line max-len
        self.plugin.command('StatusExtension.updateStatus', {rtsStatus: self.rtsStatusEnum.Offline});
        self.updateHtml(true);
      }
    );
    this.plugin.subscribe('WebChatService.error',
      function() {
        self.plugin.command('StatusExtension.stopPolling');
        // eslint-disable-next-line max-len
        self.plugin.command('StatusExtension.updateStatus', {rtsStatus: self.rtsStatusEnum.Offline});
        self.updateHtml(true);
      }
    );
    this.plugin.subscribe('WebChatService.disconnected',
      function() {
        // eslint-disable-next-line max-len
        self.plugin.command('StatusExtension.updateStatus', {rtsStatus: self.rtsStatusEnum.Offline});
        self.updateHtml(true);
      }
    );
    this.plugin.subscribe('WebChatService.reconnected',
      function() {
        self.plugin.command('StatusExtension.updateStatus', {rtsStatus: self.rtsStatusEnum.Online});
      }
    );
    this.plugin.subscribe('Toaster.opened',
      function() {
        self.updateHtml(false);
        self.plugin.command('DomExtension.toggleMopinion', {toggle: true});
      }
    );
    this.plugin.subscribe('Toaster.closed',
      function() {
        self.updateHtml(true);
        self.plugin.command('DomExtension.toggleMopinion', {toggle: false});
      }
    );
    this.plugin.subscribe('OlsExtension.olsStarted',
      function() {
        self.setUserData();
        self.updateHtml(false);
        self.startPolling(false);
      }
    );
    this.plugin.subscribe('OlsExtension.olsEnded',
      function() {
        self.resetUserData();
        self.plugin.command('WebChatService.endChat');
        self.plugin.command('WebChat.close');
        self.plugin.command('StatusExtension.stopPolling');
        self.plugin.command('DomExtension.removeOlsChatBody');
      }
    );
    this.plugin.subscribe('OlsExtension.olsChatStarted',
      function() {
        self.plugin.command('StatusExtension.getStatusOnce')
        .done(
          function(data) {
            if (data) {
              self.rtsStatus = data;
            }
            self.plugin.command('WebChat.open');
          }
        );
      }
    );
    this.plugin.republish('ready');
    this.plugin.ready();
  };

  return new FunctionalExtension();
};
