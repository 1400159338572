if (!window._genesys.widgets.extensions) {
  window._genesys.widgets.extensions = {};
}

window._genesys.widgets.extensions.RvtExtension = function($, CXBus, Common) {
  'use strict';

  function RvtExtension() {
    this.plugin = CXBus.registerPlugin('RvtExtension');
    this.setRvt(this.getRvt());
    this.pollRvt();
    this.registerAndSubscribe();
  }

  RvtExtension.prototype.getRvt = function() {
    return $('#genesys-widgets-anti-forgery-token input[type=hidden]').val();
  };

  RvtExtension.prototype.setRvt = function(data) {
    $.ajaxPrefilter(function(options, originalOptions, jqXHR) {
      if (options.url.indexOf('Genesys') !== -1 && options.type === 'POST') {
        jqXHR.setRequestHeader('__RequestVerificationToken', data);
      }
    });
  };

  RvtExtension.prototype.pollRvt = function() {
    var self;

    self = this;
    $.ajax({
      type: 'POST',
      url: '/sitecoreapi/spa/digest',
      data: null,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      },
      dataType: 'json',
      async: true
    }).done(
      function(data) {
        self.setRvt(data);
    }).always(
        setTimeout(
          function() {
            self.pollRvt();
          },
          60000
        )
    );
  };

  RvtExtension.prototype.registerAndSubscribe = function() {
    this.plugin.republish('ready');
    this.plugin.ready();
  };

  return new RvtExtension();
};
